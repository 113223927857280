import React from "react";

import DefaultLayout from "../../layouts/default";

const Datenschutz = () => (
  <DefaultLayout title="Datenschutz">
    <div className="white portrait">
      <div className="navContentSpacerHelperSingleNavBar"></div>
      <header className="navWrapper">
        <nav className="mainNav singleNavBar">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <h3 className="has-padding-top smallStaticPadding-bottom">
            Datenschutzerklärung WigaSoft
          </h3>
          <p>
            Wir legen grossen Wert darauf, dass der Umgang mit Personendaten transparent ist. Diese Datenschutzerklärung gibt Auskunft darüber, welche personenbezogenen Daten wir sammeln, zu welchem Zweck und an wen wir sie weitergeben. Um eine hohe Transparenz zu gewährleisten, wird diese Datenschutzerklärung regelmässig überprüft und aktualisiert.
            <br />
            <br />
            Bei Fragen oder Anliegen zum Schutz Ihrer Daten durch uns erreichen Sie uns jederzeit per E-Mail unter info@wigasoft.ch. Verantwortlich für die Datenbearbeitungen, die über diese Website erfolgen, ist:<br />
            <br />
            <h5 className="padding-10-bottom">WigaSoft AG</h5>
            Oberstrasse 222<br />
            9014 St. Gallen<br />
            <br />
            Datenschutzverantwortliche Person:<br />
            <br />
            Florian Wild<br />
            info@wigasoft.ch <br />
            0041 71 274 51 31<br />
            <br />
            Die WigaSoft AG ist Betreiberin der Website www.wigasoft.ch sowie der darauf angebotenen Dienstleistungen und Produkte und somit verantwortlich für die Erhebung, Verarbeitung und Nutzung Ihrer persönlichen Daten und die Vereinbarkeit der Datenbearbeitung mit dem anwendbaren Datenschutzrecht.

          </p>

          <div className="accordeonContainerDSGVO has-padding-bottom">
            <div className="tab">
              <input id="tab-one" type="checkbox" name="tabs" />
              <label for="tab-one">
                <h5>Allgemeine Grundsätze</h5>
              </label>
              <div className="tab-content">
                <div className="row">
                  <h5 className="padding-10-bottom">Welche Daten sammeln wir von Ihnen und von wem erhalten wir diese Daten</h5>
                  <p className="smallStaticPadding-bottom">
                    In erster Linie bearbeiten wir Personendaten, die Sie uns übermitteln oder die wir beim Betrieb unserer Website sammeln. Unter Umständen erhalten wir Personendaten über Sie auch von Dritten.
                  </p>
                  <h5 className="padding-10-bottom">Unter welchen Voraussetzungen bearbeiten wir Ihre Daten?</h5>
                  <p className="smallStaticPadding-bottom">
                    Wir behandeln Ihre Daten vertraulich und gemäss den in dieser Datenschutzerklärung festgelegten Zwecken. Wir achten dabei auf eine transparente und verhältnismässige Bearbeitung.</p>

                  <p>Falls wir ausnahmsweise nicht in der Lage sind, diese Grundsätze zu befolgen, kann die Datenbearbeitung trotzdem rechtmässig sein, weil ein Rechtfertigungsgrund vorliegt. Als Rechtfertigungsgrund kommt namentlich in Frage:</p>
                  <ul className="standardUL"><li>Ihre Einwilligung;</li>
                  <li>die Durchführung eines Vertrages oder vorvertraglicher Massnahmen;</li>
                  <li>unsere berechtigten Interessen, sofern Ihre Interessen nicht überwiegen.</li>
                  </ul>

                  <h5 className="padding-10-bottom">Wie können Sie Ihre Einwilligung widerrufen?</h5>
                  <p className="smallStaticPadding-bottom">
                    Haben Sie uns eine Einwilligung zur Bearbeitung Ihrer personenbezogenen Daten für bestimmte Zwecke erteilt, bearbeiten wir Ihre Daten im Rahmen dieser Einwilligung, soweit wir keinen anderen Rechtfertigungsgrund haben.</p>
                  <p className="smallStaticPadding-bottom">
Sie haben jederzeit die Möglichkeit, Ihre Einwilligung zu widerrufen, indem Sie eine E-Mail mit einem Identitätsnachweis an die Kontaktinformationen hinterlegte Mail-Adresse schicken. Bereits erfolgte Datenverarbeitungen sind davon nicht betroffen.</p>
                  <h5 className="padding-10-bottom">
                    In welchen Fällen können wir Ihre Daten an Dritte weitergeben?
                  </h5>
                  <p>Grundsatz</p>
                  <p className="smallStaticPadding-bottom">
                    Wir sind unter Umständen darauf angewiesen, die Dienste Dritter oder von verbundenen Unternehmen in Anspruch zu nehmen und diese mit der Verarbeitung Ihrer Daten zu beauftragen (sog. Auftragsverarbeiter). Wir stellen sicher, dass diese Dritten und unsere verbundenen Unternehmen die Voraussetzungen des Datenschutzes einhalten und Ihre personenbezogenen Daten vertraulich behandeln.</p>
                    <p className="smallStaticPadding-bottom">Unter Umständen sind wir auch verpflichtet, Ihre personenbezogenen Daten an Behörden bekanntzugeben.</p>
                    <p>Besuch unserer Social-Media-Kanäle</p>
                    <p className="smallStaticPadding-bottom">Wir haben auf unserer Website Links zu unseren Social-Media-Kanälen eingebettet. Das ist für Sie jeweils ersichtlich (typischerweise über entsprechende Symbole). Klicken Sie auf die Symbole, werden Sie auf unsere Social-Media-Kanäle weitergeleitet. </p>
                    <p className="smallStaticPadding-bottom">Die Social-Media Anbieter erfahren in diesem Fall, dass Sie von unserer Website auf deren Plattform zugreifen. Die Social-Media Anbieter können die so erhobenen Daten für eigene Zwecke nutzen. Wir weisen darauf hin, dass wir keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch die Betreiber erhalten.</p>
                    <p>Weitergabe ins Ausland</p>
                    <p className="smallStaticPadding-bottom">Unter Umständen kann es im Rahmen der Auftragsbearbeitung zu Übermittlung Ihrer personenbezogenen Daten an Unternehmen im Ausland kommen. Diese Unternehmen sind im gleichen Umfang zum Datenschutz verpflichtet, wie wir selbst. Die Übermittlung kann weltweit stattfinden. </p>
                    <p>Entspricht das Datenschutzniveau nicht demjenigen des der Schweiz, so nehmen wir eine vorgängige Risikoeinschätzung vor und stellen vertraglich sicher, dass der gleiche Schutz wie in der Schweiz garantiert wird (bspw. mittels der neuen Standardvertragsklauseln der EU-Kommission oder anderen, gesetzlich vorgegebenen Massnahmen). Sollte unsere Risikoeinschätzung negativ ausfallen, ergreifen wir zusätzliche technische Massnahmen zum Schutz Ihrer Daten. Sie können die Standardvertragsklauseln der EU-Kommission abrufen unter folgendem Link. https://commission.europa.eu/publications/standard-contractual-clauses-controllers-and-processors-eueea_de<br /><br /></p>

                  <h5 className="padding-10-bottom">Wie lange bewahren wir Ihre Daten auf?</h5>
                  <p className="smallStaticPadding-bottom">
                    Wir speichern personenbezogene Daten nur so lange, wie dies erforderlich ist, um die einzelnen Zwecke, zu denen die Daten erhoben wurden, zu erfüllen.
                  </p>
                  <p className="smallStaticPadding-bottom">Vertragsdaten speichern wir länger, da wir dazu durch gesetzliche Vorschriften verpflichtet sind. Wir müssen insbesondere geschäftliche Kommunikation, geschlossene Verträge und Buchungsbelege bis zu 10 Jahren aufbewahren. Soweit wir solche Daten von Ihnen nicht mehr zur Durchführung der Dienstleistungen benötigen, werden die Daten gesperrt und wir verwenden sie nur noch für Zwecke der Rechnungslegung und für Steuerzwecke.</p>
                  <h5 className="padding-10-bottom">Wie schützen wir Ihre Daten?</h5>
                  <p className="smallStaticPadding-bottom">
                    Wir werden Ihre Daten sicher aufbewahren und alle angemessenen Massnahmen ergreifen, um Ihre Daten vor Verlust, Zugriff, Missbrauch oder Änderungen zu schützen.
                  </p>
                  <h5 className="padding-10-bottom">
                    Verantwortlicher oder für die Verarbeitung Verantwortlicher
                  </h5>
                  <p className="smallStaticPadding-bottom">
                    Verantwortlicher oder für die Verarbeitung Verantwortlicher
                    ist die natürliche oder juristische Person, Behörde,
                    Einrichtung oder andere Stelle, die allein oder gemeinsam
                    mit anderen über die Zwecke und Mittel der Verarbeitung von
                    personenbezogenen Daten entscheidet. Sind die Zwecke und
                    Mittel dieser Verarbeitung durch das Unionsrecht oder das
                    Recht der Mitgliedstaaten vorgegeben, so kann der
                    Verantwortliche beziehungsweise können die bestimmten
                    Kriterien seiner Benennung nach dem Unionsrecht oder dem
                    Recht der Mitgliedstaaten vorgesehen werden.
                  </p>
                  <p className="smallStaticPadding-bottom">Unsere Vertragspartner und Mitarbeitende, die Zugang zu Ihren Daten haben, sind zur Einhaltung der datenschutzrechtlichen Bestimmungen verpflichtet. In manchen Fällen wird es erforderlich sein, dass wir Ihre Anfragen an mit uns verbundene Unternehmen weiterreichen. Auch in diesen Fällen werden Ihre Daten vertraulich behandelt.</p>
                  <h5 className="padding-10-bottom">Welche Rechte haben Sie?</h5>
                  <p>Auskunftsrecht</p>
                  <p className="smallStaticPadding-bottom">
                    Sie können jederzeit eine Auskunft, über die von uns über Sie gespeicherten Daten verlangen. Wir bitten Sie, Ihr Auskunftsgesuch zusammen mit einem Identitätsnachweis an die Kontaktinformationen hinterlegte Mail-Adresse zu senden.
                  </p>
                  <p>Sie haben ausserdem das Recht, Ihre Daten in einem gängigen Dateiformat zu erhalten, wenn wir Ihre Daten automatisiert bearbeiten, und wenn:</p>
                  <ul className="standardUL">
                    <li>Sie Ihre Einwilligung für die Bearbeitung dieser Daten erteilt haben; oder</li>
                  <li>Sie Daten im Zusammenhang mit dem Abschluss oder der Abwicklung eines Vertrags bekannt gegeben ha-ben.</li>
                  </ul>
                  <p className="smallStaticPadding-bottom">Wir können die Auskunft bzw. Datenherausgabe einschränken oder verweigern, wenn dies unseren gesetzlichen Verpflichtungen, berechtigten eigenen oder öffentlichen Interessen oder Interessen einer Drittperson entgegensteht.</p>

                  <p className="smallStaticPadding-bottom">Die Bearbeitung Ihres Gesuchs unterliegt der gesetzlichen Bearbeitungsfrist von 30 Tagen. Diese Frist dürfen wir jedoch aufgrund von hohem Anfragevolumen, aus rechtlichen oder technischen Gründen oder weil wir nähere Angaben von Ihnen benötigen, verlängern. Sie werden über die Fristverlängerung rechtzeitig mindestens in Textform informiert.</p>

                  <p>Löschung und Berichtigung</p>
                  <p className="smallStaticPadding-bottom">
                    Sie haben jederzeit die Möglichkeit, die Löschung oder Berichtigung Ihrer Daten zu verlangen. Wir können das Gesuch abweisen, wenn gesetzliche Vorschriften uns zur längeren bzw. unveränderten Aufbewahrung verpflichten oder ein Erlaubnistatbestand Ihrem Gesuch entgegensteht.
                  </p>
                  <p className="smallStaticPadding-bottom">
                    Bitte beachten Sie, dass die Ausübung Ihrer Rechte unter Umständen im Konflikt mit vertraglichen Abmachungen stehen und entsprechende Auswirkungen auf die Vertragsdurchführung haben kann (z.B. vorzeitige Vertragsauflösung oder Kostenfolgen).
                  </p>
                  <p>Rechtsweg</p>
                  <p>Sind Sie von der Bearbeitung personenbezogener Daten betroffen, haben Sie das Recht, Ihre Rechte gerichtlich durchzusetzen oder bei der zuständigen Aufsichtsbehörde eine Meldung einzureichen. Die zuständige Aufsichtsbehörde in der Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte: https://www.edoeb.admin.ch.</p>

                </div>
              </div>
            </div>
            <div className="tab">
              <input id="tab-two" type="checkbox" name="tabs" />
              <label for="tab-two">
                <h5>Cookies, Tracking und andere Technologien, die mit der Nutzung unserer Website zusammenhängen</h5>
              </label>
              <div className="tab-content">
                <div className="row">
                  
                  <h5 className="padding-10-bottom">
                    Kontaktformular und E-Mail
                  </h5>
                  <p className="smallStaticPadding-bottom">
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                  </p>
                  <p>Microsoft 365</p>

                  <p className="smallStaticPadding-bottom">
                    Für verschiedene Büro- und Kommunikationsaufgaben nutzen wir Microsoft 365, einen Dienst von Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA.
                  </p>
                  <p className="smallStaticPadding-bottom">
                    Microsoft 365 bietet eine Reihe von Produktivitätstools, einschliesslich E-Mail (Outlook), Dokumentenspeicherung (OneDrive), Kollaborationstools (Teams) und Office-Anwendungen wie Word, Excel und PowerPoint. Wenn Sie mit uns in Kontakt treten, beispielsweise per E-Mail, oder wenn wir Dokumente teilen, werden diese Interaktionen über Microsoft 365 verarbeitet und gespeichert.
                  </p>
                  <p className="smallStaticPadding-bottom">
                    Microsoft verwendet Server in verschiedenen Ländern, um die Dienste von Microsoft 365 bereitzustellen. Dies bedeutet, dass Ihre Daten möglicherweise in Datenzentren ausserhalb der Schweiz gespeichert werden. Microsoft hat jedoch verschiedene Sicherheits- und Datenschutzmassnahmen implementiert, um die Daten ihrer Kunden zu schützen.
                  </p>

                  <h5 className="padding-10-bottom">
                    Newsletter
                  </h5>
                  <p className="smallStaticPadding-bottom">Wenn Sie den auf der Webseite angebotenen Newsletter empfangen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht erhoben. Diese Daten verwenden wir ausschliesslich für den Versand der angeforderten Informationen und geben sie nicht an Dritte weiter.</p>
                  <p className="smallStaticPadding-bottom">Nachdem Sie Ihre Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters erteilt haben, können Sie diese jederzeit widerrufen, etwa über den "Abmeldelink" im Newsletter.</p>
                  <p>Mailjet</p>
                  <p className="smallStaticPadding-bottom">Für den Versand von Newslettern und E-Mail-Benachrichtigungen nutzen wir den Dienst Mailjet, angeboten von Mailjet SAS, 13-13 bis, rue de l’Aubrac, 75012 Paris, Frankreich.</p>
                  <p className="smallStaticPadding-bottom">Wenn Sie sich für unseren Newsletter anmelden oder andere E-Mail-Kommunikationen von uns anfordern, werden die von Ihnen angegebenen Daten, wie Ihre E-Mail-Adresse, Ihr Name und gegebenenfalls weitere Informationen, an Mailjet übertragen und dort gespeichert. Dies ermöglicht uns, Ihnen regelmässige Updates, Angebote und andere Informationen zuzusenden.</p>
                  <p className="smallStaticPadding-bottom">Mailjet bietet uns umfangreiche Analysemöglichkeiten darüber, wie die versendeten E-Mails geöffnet und benutzt werden. Diese Analysen sind gruppenbezogen und werden von uns nicht zur individuellen Auswertung verwendet.</p>
                  <p className="smallStaticPadding-bottom">Die bei Mailjet gespeicherten Daten werden gelöscht, sobald Sie sich von unserem Newsletter abmelden oder Ihre Einwilligung zur Speicherung widerrufen. Dies beeinträchtigt nicht Daten, die zu anderen Zwecken bei uns gespeichert sind.</p>
                  <p className="smallStaticPadding-bottom">Weitere Informationen zum Datenschutz bei Mailjet finden Sie in der Datenschutzerklärung von Mailjet: https://www.mailjet.com/legal/privacy-policy/. Wenn Sie Fragen zu den von Mailjet erfassten Daten haben oder Ihre Rechte in Bezug auf diese Daten ausüben möchten, sollten Sie sich direkt an Mailjet SAS wenden.</p>
                
                  <h5 className="padding-10-bottom">
                    Bewerbungen
                  </h5>
                  <p className="smallStaticPadding-bottom">
                    Über unsere Website (Stellenausschreibung) und die als Link bereitgestellte E-Mail-Adresse können wir kontaktiert werden. In diesem Fall werden Ihre mit der Anfrage übermittelten personenbezogenen Daten (in jedem Fall Vor- und Nachname und E-Mail-Adresse) sowie Ihre Nachricht gespeichert. Die Verarbeitung dieser personenbezogenen Daten dient uns zur Bearbeitung der Kontaktaufnahme und Ihres Anliegens. Rechtsgrundlage für die Verarbeitung dieser Daten, die im Zuge einer Übersendung einer Anfrage übermittelt werden, ist ein berechtigtes Interesse von uns als Verantwortliche an der Kommunikation mit den Nutzern unserer Website. Zielt die Anfrage auf den Abschluss eines Vertrags ab, so ist die zusätzliche Rechtsgrundlage die Erfüllung eines Vertrags (bzw. vorvertragliche Massnahmen hierzu).
                  </p>
                  <h5 className="padding-10-bottom">
                    Datenerfassung durch den Website-Hosting Anbieter
                  </h5>
                  <p className="smallStaticPadding-bottom">Unsere Server/Hosting Anbieter ist GoEast GmbH, Oberstrasse 222, 9014 St. Gallen, Schweiz.</p>
                  <p className="smallStaticPadding-bottom">Die Webserver der GoEast GmbH erfassen mit jedem Aufruf unserer Website allgemeine Daten und Informationen. Diese werden in den Logfiles des Servers gespeichert. Erfasst werden u.a. der verwendete Browser und dessen Version, das Betriebssystem, die Referrer Internetseite, die Unterwebseiten, das Datum und die Uhrzeit des Zugriffs, eine Internet-Protokoll-Adresse (IP), der Internet-Service-Provider (ISP) und andere Daten und Informationen, die der Abwehr von Gefahren im Falle von Systemangriffen dienen.</p>
                  <p className="smallStaticPadding-bottom">Durch diese allgemeinen Daten und Informationen ziehen wir keine Rückschlüsse auf die Person. Vielmehr werden diese Informationen genutzt, um die Inhalte korrekt zu liefern, die Website zu optimieren, die Funktionsfähigkeit unserer IT-Systeme zu gewährleisten und im Falle eines Cyberangriffs der Strafverfolgungsbehörde die nötigen Informationen bereitstellen zu können.</p>
                  <p className="smallStaticPadding-bottom">Die Daten können statistisch ausgewertet werden, allerdings wird kein Personenbezug hergestellt. Die anonymen Daten der Server-Logdateien werden separat von allen durch eine Person angegebenen personenbezogenen Daten sechs Monate gespeichert.</p>
                  <p>Cookies</p>
                  <p className="smallStaticPadding-bottom">Unsere Website verwendet Cookies. Dabei handelt es sich um kleine Textdateien, die es ermöglichen, spezifische, auf den Nutzer bezogene Informationen auf dem Endgerät des Nutzers zu speichern, während der Nutzer die Website nutzt. Cookies ermöglichen es insbesondere, die Nutzungshäufigkeit und die Anzahl der Nutzer der Seiten zu ermitteln, Verhaltensmuster der Seitennutzung zu analysieren, aber auch, unser Angebot kundenfreundlicher zu gestalten. Cookies bleiben über das Ende einer Browser-Sitzung hinaus gespeichert und können bei einem erneuten Besuch der Seite wieder abgerufen werden. Wenn Sie dies nicht wünschen, sollten Sie Ihren Internet-Browser so einstellen, dass er die Annahme von Cookies verweigert.</p>
                  <p className="smallStaticPadding-bottom">Ein genereller Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken kann für eine Vielzahl der Dienste, insbesondere beim Tracking, über die US-Seite http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Darüber hinaus kann die Speicherung von Cookies durch Deaktivierung in den Browsereinstellungen erreicht werden. Bitte beachten Sie, dass in diesem Fall nicht alle Funktionen dieses Online-Angebots genutzt werden können.</p>
                  <p>Matomo Analytics</p>
                  <p className="smallStaticPadding-bottom">Wir verwenden auf unserer Website den Dienst "Matomo Analytics", der von der InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland bereitgestellt wird. Matomo ist eine Open-Source-Software, die detaillierte Webanalysen ermöglicht und dabei grossen Wert auf den Schutz der Privatsphäre der Nutzer legt.</p>
                  <p className="smallStaticPadding-bottom">Mit Hilfe von Matomo Analytics können wir das Verhalten unserer Besucher analysieren, um das Nutzererlebnis und die Inhalte unserer Website stetig zu verbessern. Dies beinhaltet Informationen darüber, von welcher Website ein Besucher kommt, welche Bereiche unserer Seite er besucht und wie oft und wie lange er diese Bereiche betrachtet.</p>
                  <p className="smallStaticPadding-bottom">Matomo Analytics verwendet Cookies, um die Analyse des Nutzerverhaltens zu ermöglichen. Diese Cookies sammeln Informationen in anonymisierter Form, einschliesslich der Anzahl der Website-Besucher, von woher die Besucher auf die Website gekommen sind und welche Seiten sie besucht haben. Da der Datenschutz bei Matomo einen hohen Stellenwert hat, werden IP-Adressen vor der Speicherung anonymisiert.</p>
                  <p className="smallStaticPadding-bottom">Einer der Hauptgründe für die Verwendung von Matomo Analytics ist der Datenschutz. Im Gegensatz zu anderen Analyse-Tools werden die Daten nicht an Dritte weitergegeben.</p>
                  <p>Imgix</p>
                  <p className="smallStaticPadding-bottom">Diese Website nutzt den Dienst imgix zur Optimierung von Bildern in Echtzeit, imgix Inc., 423 Tehama St. San Francisco, CA 94103, USA. Dabei wird Ihre IP-Adresse an imgix geschickt. Der Dienst hat sich verpflichtet, die europäischen Datenschutzrichtlinien einzuhalten und ist dem EU-US Abkommen Privacy Shield beigetreten. Bei weiteren Fragen zum Datenschutz bei imgix:privacy@imgix.com.</p>
                </div>
              </div>
            </div>
            <div className="tab">
              <input id="tab-three" type="checkbox" name="tabs" />
              <label for="tab-three">
                <h5>Datensicherheit</h5>
              </label>
              <div className="tab-content">
                <div className="row">
                  <p>
                    Wir treffen angemessene technische und organisatorische Sicherheitsvorkehrungen zum Schutz Ihrer Personendaten vor unberechtigtem Zugriff und Missbrauch wie etwa der Erlass von Weisungen, Schulungen, IT- und Netzwerksicherheitslösungen, Zugangskontrollen und –beschränkungen, Verschlüsselung von Datenträgern und Übermittlungen, Pseudonymisierung, Kontrollen.
                  </p>
                </div>
              </div>
            </div>
            <div className="tab">
              <input id="tab-four" type="checkbox" name="tabs" />
              <label for="tab-four">
                <h5>SSL-Verschlüsselung</h5>
              </label>
              <div className="tab-content">
                <div className="row">
                  <p>
                    Diese Website verwendet aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie z.B. Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL/TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                  </p>
                  <p>
                    Wenn die SSL- oder TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten gelesen werden.
                  </p>
                </div>
              </div>
            </div>
            <div className="tab">
              <input id="tab-five" type="checkbox" name="tabs" />
              <label for="tab-five">
                <h5>
                  Remote Support
                </h5>
              </label>
              <div className="tab-content">
                <div className="row">
                  <p className="smallStaticPadding-bottom">
                    Für den Remote Support nutzen wir die Software und Dienstleistungen der TeamViewer GmbH, Jahnstrasse 30, 73037 Göppingen, Deutschland. Hierzu bieten wir Ihnen den Download und die Nutzung einer für diese Zwecke geeigneten TeamViewer-Software von unserer Website an. Um den von Ihnen angefragten Support zu erbringen kann die Verbindung und u.U. über TeamViewer abgewickelte Dateitransfers laufen dabei über Server der TeamViewer GmbH. So speichert der Anbieter beispielsweise Daten wie z.B. Usernamen, E-Mail-Adressen und IP-Adressen. Mit dem Download der Software willigen Sie in die Verarbeitung ein. Die Nutzung von TeamViewer erfolgt zur Erfüllung des zwischen Ihnen und uns bestehenden Vertrags und im beiderseitigen Interesse der effizienten, kurzfristigen und kostengünstigen Erbringung von Support-Dienstleistungen durch uns. Durch den Besuch der Unterseite unserer Website, auf der wir den Download der TeamViewer-Software anbieten, erhält TeamViewer die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen bzw. die Software heruntergeladen haben. Wir haben keine Kenntnis darüber, für, ob und ggf. für welche eigenen Zwecke TeamViewer diese Informationen nutzt. Die Nutzung von TeamViewer selbst erfolgt gemäss der Datenschutzerklärung der TeamViewer GmbH. 
                  </p>
                </div>
              </div>
            </div>

            <div className="tab">
              <input id="tab-six" type="checkbox" name="tabs" />
              <label for="tab-six">
                <h5>Änderungen der Datenschutzhinweise</h5>
              </label>
              <div className="tab-content">
                <div className="row">
                  <p className="smallStaticPadding-bottom">
                    Wir können diese Datenschutzrichtlinie jederzeit ohne vorherige Ankündigung ändern. Es gilt die jeweils aktuelle, auf unserer Website veröffentlichte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung per E-Mail oder auf andere geeignete Weise über die Änderung informieren.
                  </p>
                  <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand März 2024. Durch die Weiterentwicklung der Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit unter https://wigasoft.ch/datenschutz/ abgerufen und bei Bedarf ausgedruckt werde.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

          <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>
      <footer className="mainFooter">
        <div className="wrapper">
          <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
            <ul>
              <li>
                <a href="/datenschutz/">Datenschutzerklärung</a>
              </li>
            </ul>
          </div>
          <div className="footerNotAnotherFlexboxButAGrid linklist">
            <div className="flex-item1">
              <ul className="standardUL">
                <li>
                  <a href="/dokumentationsloesungen/heime/">
                    <strong>Heime</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/spitaeler/">
                    <strong>Spitäler</strong>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/rehabilitation/">
                    <strong>Rehabilitation</strong>
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item2">
              <ul className="standardUL">
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -L BESA
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -L RAI-NH
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-b/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -B
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -H
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-doc-r/">
                    <span className="is-visible-desktop">WiCare|Doc</span>
                    <span className="is-visible-tablet-until-PC">WCD</span>
                    -R
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item3">
              <ul>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    WiCare|now tacs<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-lep/">
                    WiCare|now LEP<sup>&#174;</sup>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/dokumentationsloesungen/wicare-le/">
                    WiCare|now LE
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item4">
              <ul>
                <li>
                  <a href="/portrait/">
                    <strong>Über uns</strong>
                  </a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/lehrlinge/">Lernende</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/partner/">Partner</a>
                </li>
                <li className="partiallyHidden">
                  <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                </li>
                <li>
                  <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                </li>
              </ul>
            </div>
            <div className="flex-item5">
              <ul>
                <li>
                  <a href="/support/" className="supportLink">
                    <strong>Support</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row copyright">
          <div className="wrapper">
            <div className="column">
              © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
            </div>
            <div className="column has-padding-left">
              <ul>
                <li>
                  <a href="/datenschutz/">Datenschutzerklärung</a>
                </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
              </ul>
            </div>
            <div className="column align-right">
              <a href="/portrait/kontakt/" className="anfahrt">
                <img
                  id="location-pointer"
                  src="/assets/images/icons/icon-anfahrt.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </DefaultLayout>
);

export default Datenschutz;
